import React from 'react'
import { Layout, SEO } from '../components/Common'
import { Styling, Content } from '../components/Common/Global/404_style'

export default () => (
	<Styling>
		<Layout>
			<SEO title="404: Not found" location="/404" />
			<Content>
			<h1>404</h1>
			<h2>Oops... Page not found.</h2>
			<p>You just hit a route that doesn&#39;t exist.</p>
			<p>I don't know why you're poking around, but I like you.</p>
			</Content>
		</Layout>
	</Styling>
)
