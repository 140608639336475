import styled from 'styled-components';

export const Content = styled.div`
  padding-bottom: 10vh;
`

export const Styling = styled.div`
  h1{
    font-size: calc(6vw + 2vh + 2vmin);
    font-family: 'Playfair Display', serif;
  }
  h2{
    font-size: calc(2vw + 2vh + 2vmin);
    font-family: 'Playfair Display', serif;
    padding-bottom: 15vh;
  }
  p{
    font-size: calc(1.5vw + 1vh + 1vmin);
    font-family: 'IBM Plex Mono', serif;
  }
  position: relative;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  padding-top: 5vh;
  color: #f9f5f1;
`